/************************************************/
/****************** NAVIGATION ******************/
/************************************************/
.responsive_nav_mobile {
    display: none;
}

nav {
    color: #1d1f20;
    left: 0;
    position: relative;
    top: 0;
    width: 100%;
    z-index: 9999;
}

/*** INPUT ***/
nav input {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

/*** LABEL ***/
nav label {
    cursor: pointer;
    display: block;
    font: 1.5em/1 'Oswald', sans-serif;
    /* padding: 0.5em; */
}

/* Hamburger Icon */
#menu-icon,
#menu-icon:before,
#menu-icon:after {
    background: #1d1f20;
    border-radius: 0.05em;
    height: 0.2em;
    transition: all 0.2s ease-in-out;
    width: 100%;
}

/* #menu-icon2,
#menu-icon2:before,
#menu-icon2:after {
  background: #FFFFFF !important;
} */

#menu-icon {
    display: inline-block;
    margin: 0.4em 0;
    max-width: 1em;
    position: relative;
}

nav label #menu-icon {
    float: right;
}

#menu-icon:before,
#menu-icon:after {
    content: '';
    left: 0;
    position: absolute;
}

#menu-icon:before {
    top: -0.4em;
}

#menu-icon:after {
    bottom: -0.4em;
}

/* Close Icon */
nav input[type=checkbox]:checked+label #menu-icon {
    background: transparent;
}

nav input[type=checkbox]:checked+label #menu-icon:before {
    top: 0;
    transform: rotate(-45deg);
}

nav input[type=checkbox]:checked+label #menu-icon:after {
    bottom: 0;
    transform: rotate(45deg);
}


/*** MENU ***/
/* Overlay */
nav input:checked~#overlay {
    background: #FFFFFF;
    bottom: 0;
    left: 0;
    height: 100vh;
    position: fixed;
    right: 0;
    top: 0;
    width: 100vw;
    z-index: -1;
    overflow: hidden;
}

/* List */
nav ul {
    font-size: 1rem;
    list-style: none;
    margin: 0;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    text-align: center;
}

nav input:checked~ul {
    margin: 1em;
    max-height: inherit;
    opacity: 1;
}

nav ul>li {
    margin: 0.5em 0;
}

nav ul a {
    color: inherit;
    text-decoration: none;
}

nav ul a:hover {
    text-decoration: none;
}

/* Social Media */
nav #social-media {
    display: none;
}

@media only screen and (min-width: 240px) and (max-width: 767px) {
    .responsive_nav_mobile {
        display: block;
    }

    .nav_links {
        display: none !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .responsive_nav_mobile {
        display: block;
    }

    .nav_links {
        display: none !important;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
    .responsive_nav_mobile {
        display: none;
    }
}